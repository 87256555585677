var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "search-area" }, [
      _c("div", { staticClass: "search-area-contianer" }, [
        _c("div", { staticClass: "search-input" }, [
          _c(
            "div",
            {
              staticClass: "search-city-btn",
              attrs: { title: _vm.selectedCity.text },
            },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    width: "629",
                    "visible-arrow": _vm.provinceCityConfig.arrowIsShow,
                    trigger: "click",
                    offset: -86,
                  },
                  model: {
                    value: _vm.provinceCityConfig.provinceCityIsShow,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.provinceCityConfig,
                        "provinceCityIsShow",
                        $$v
                      )
                    },
                    expression: "provinceCityConfig.provinceCityIsShow",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "search-city-content",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.selectedCity.text) +
                          "\n                                "
                      ),
                      _c("span", { staticClass: "el-icon-arrow-down" }),
                    ]
                  ),
                  _c("province-city", {
                    ref: "provinceCity",
                    attrs: {
                      "grid-length": 7,
                      enableHeader: _vm.provinceCityConfig.enableHeader,
                      enableFooter: _vm.provinceCityConfig.enableFooter,
                    },
                    on: {
                      confirm: _vm.handleCitySelectConfirm,
                      cancel: _vm.handleCitySelectCancel,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-keywords-input" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    width: "348",
                    "visible-arrow": _vm.searchHistoryConfig.arrowIsShow,
                    "popper-class": _vm.searchHistoryPoperClass,
                    trigger: "click",
                  },
                  model: {
                    value: _vm.searchHistoryConfig.searchHistoryIsShow,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.searchHistoryConfig,
                        "searchHistoryIsShow",
                        $$v
                      )
                    },
                    expression: "searchHistoryConfig.searchHistoryIsShow",
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchParams.keyWords,
                        expression: "searchParams.keyWords",
                      },
                    ],
                    attrs: {
                      slot: "reference",
                      maxlength: "100",
                      type: "text",
                      placeholder: "输入关键字，多个关键字用空格隔开",
                    },
                    domProps: { value: _vm.searchParams.keyWords },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.searchParams,
                            "keyWords",
                            $event.target.value
                          )
                        },
                        _vm.handleInput,
                      ],
                    },
                    slot: "reference",
                  }),
                  _c("div", { staticClass: "search-history" }, [
                    _c("div", { staticClass: "search-history-header" }, [
                      _vm._v(
                        "\n                                    搜索历史\n                                "
                      ),
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.searchHistory, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: "search_history" + index,
                            staticClass: "search-history-li",
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectKeyWordsHistory(
                                  item.text
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemoveHistory(index, item)
                                },
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(item.text))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-btn", on: { click: _vm.handleSearch } },
            [_c("span", { staticClass: "el-icon-search" })]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "search-area-contianer more-btn",
          on: {
            click: function ($event) {
              return _vm.handleShowAdvanced(false)
            },
          },
        },
        [
          _vm._v("\n                更多搜索条件\n                "),
          _c("span", {
            class: !_vm.bubbleIsShow
              ? "el-icon-arrow-up"
              : "el-icon-arrow-down",
          }),
        ]
      ),
      _c("p", { staticClass: "search-area-tip" }, [
        _vm._v(
          "\n                更新当前雇主公司和职位可得15积分\n            "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "upload-excel-btn",
          class:
            _vm.tableType != "trackingList" || _vm.userRole == 0
              ? "single"
              : "",
          on: { click: _vm.goToExcelUpload },
        },
        [
          _c("i", { staticClass: "icon-font icon-resume_ic_uppload_large" }),
          _vm._v("\n                上传我的excel\n            "),
        ]
      ),
      _vm.tableType == "trackingList" && _vm.userRole == 1
        ? _c(
            "div",
            {
              staticClass: "upload-excel-btn leader-feedback-btn",
              on: { click: _vm.jumpLeaderFeedback },
            },
            [_vm._v("\n                Leader反馈\n            ")]
          )
        : _vm._e(),
      _vm.tableType == "trackingList" &&
      _vm.userRole == 2 &&
      _vm.secondLevelTeams.length > 1
        ? _c(
            "div",
            { staticClass: "upload-excel-btn leader-feedback-btn" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click", placement: "bottom" },
                  on: { command: _vm.jumpWeekReport },
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(
                      "\n                        Tracking List周报\n                    "
                    ),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "leader-feedback-menu",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.secondLevelTeams, function (team) {
                      return _c(
                        "el-dropdown-item",
                        { key: team.teamId, attrs: { command: team } },
                        [_vm._v(_vm._s(team.realName || team.nickName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.tableType == "trackingList" &&
      _vm.userRole == 2 &&
      _vm.secondLevelTeams.length == 1
        ? _c(
            "div",
            {
              staticClass: "upload-excel-btn leader-feedback-btn",
              on: {
                click: function ($event) {
                  return _vm.jumpWeekReport()
                },
              },
            },
            [_vm._v("\n                Tracking List周报\n            ")]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.bubbleIsShow,
            expression: "!bubbleIsShow",
          },
        ],
        staticClass: "draw-cell",
      },
      [
        _c("select-bubble", {
          ref: "selectBubble",
          class: { isShow: !_vm.bubbleIsShow },
          attrs: { "table-type": _vm.tableType },
          on: {
            handleCleanLabel: _vm.handleCleanLabel,
            handleAddLabel: _vm.handleAddLabel,
          },
        }),
        _c(
          "div",
          {
            staticClass: "search-advanced",
            class: { isShow: !_vm.bubbleIsShow },
          },
          [
            _c(
              "div",
              { staticClass: "search-advanced-list" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "search-advanced-params",
                    attrs: { maxlength: "100", placeholder: "职位" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchParams.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "title", $$v)
                      },
                      expression: "searchParams.title",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-font icon-zhiwei1",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
                _c(
                  "el-input",
                  {
                    staticClass: "search-advanced-params",
                    attrs: { maxlength: "100", placeholder: "公司" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchParams.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "companyName", $$v)
                      },
                      expression: "searchParams.companyName",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-font icon-company",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
                _c(
                  "el-input",
                  {
                    staticClass: "search-advanced-params",
                    attrs: { maxlength: "100", placeholder: "姓名" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchParams.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "realName", $$v)
                      },
                      expression: "searchParams.realName",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-font icon-ic_xingming_",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
                _c(
                  "el-input",
                  {
                    staticClass: "search-advanced-params el-input-mobile",
                    class: { "is-error": _vm.isMobileFromatError },
                    attrs: { maxlength: "15", placeholder: "手机" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchParams.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "mobile", $$v)
                      },
                      expression: "searchParams.mobile",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-font icon-ic_shouji_",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
                _c(
                  "el-input",
                  {
                    staticClass: "search-advanced-params",
                    class: { "is-error": _vm.isEmailFormatError },
                    attrs: { maxlength: "100", placeholder: "邮箱" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchParams.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "email", $$v)
                      },
                      expression: "searchParams.email",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-font icon-ic_youxiang_",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
                _c(
                  "el-input",
                  {
                    staticClass: "search-advanced-params",
                    attrs: { maxlength: "100", placeholder: "寻访内容" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchParams.inquiryLogContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "inquiryLogContent", $$v)
                      },
                      expression: "searchParams.inquiryLogContent",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-font icon-ic_neirong_",
                      attrs: { slot: "prefix" },
                      slot: "prefix",
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-reset",
                    on: {
                      click: function ($event) {
                        return _vm.handleResetParam(false)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        重置\n                    "
                    ),
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn-search",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleSearch },
                  },
                  [
                    _vm._v(
                      "\n                        确定\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }